import React, { useState, useEffect } from 'react';
import {
    Row, Col, Button, Form,
} from 'react-bootstrap';
import ReUsableModal from '../../../../../components/ReUsableModal';
import axios from 'axios';
import { API_SERVER, notificationConfigs, } from '../../../../../config/constant';
import { Store } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { ACCOUNT_INITIALIZE } from '../../../../../store/actions';

const SwitchLabModal = (props) => {
    const dispatcher = useDispatch();

    const [selectedLabo, setSelectedLabo] = useState(null);
    const { account } = props;

    /**
     * 
     * @returns The list of laboratories
     */
    const fetchLaboratories = () => {
        return new Promise(async (resolve, reject) => {
            axios
                .get(API_SERVER + 'admin/configs/labs/all').then((res) => {
                    resolve({ data: res.data });
                }).catch((err) => {
                    reject(err);
                })
        });
    }

    const laboratoriesResponse = useQuery('getLaboratories', fetchLaboratories, {
        refetchOnWindowFocus: false,
        enabled: false
    });

    useEffect(() => {
        laboratoriesResponse.refetch();
    }, []);

    const setNewLabo = (labUuid) => {
        laboratoriesResponse?.data?.data?.laboratories?.docs?.map((labo) => {
            if (labUuid === labo.uuid) setSelectedLabo(labo);
            return true;
        })
    }

    const validateSwitchAndRefresh = () => {
        dispatcher({
            type: ACCOUNT_INITIALIZE,
            payload: {
                isLoggedIn: account.isLoggedIn,
                user: {
                    ...account.user,
                    laboratory: selectedLabo.uuid,
                    laboratoryAbbreviation: selectedLabo.laboratoryAbbreviation,
                    laboratoryName: selectedLabo.laboratoryName
                },
                token: account.token
            }
        });

        Store.addNotification({
            ...notificationConfigs,
            title: `${selectedLabo.laboratoryName} 😎`,
            message: "Vous êtes désormais connecté à ce Labo!",
        });

        //Refresh the page
        window.location.reload(true);
    }

    console.log({laboratoriesResponse});


    return (
        <React.Fragment>
            <ReUsableModal setShowStateOnClose={(value) => props.setShowStateOnClose(value)} show={true} title={`Changer de Laboratoire`} closeWhenClickOutside={true} size={"lg"}>
                <Row>
                    <Col xs={1}></Col>
                    <Col xs={10}>
                        <h5 className='text-center'>{`Vous êtes actuellement connecté au: `}
                            <b>{account.user.laboratoryName}</b>
                        </h5>
                        <p className='text-center'>{`Veuillez sélectionner un autre Laboratoire pour vous connecter à ce dernier`}</p>
                        <br />
                        <div>

                            <Row>
                                <Form.Group as={Col} controlId="formGridlaboratory">
                                    {/* <Form.Label>Laboratoire</Form.Label> */}
                                    <Form.Control
                                        name='laboratory'
                                        as="select"
                                        // defaultValue={account.user.laboratory}
                                        defaultValue={''}
                                        onChange={(e) => setNewLabo(e.target.value)}
                                    >
                                        <option value={''} disabled>Choisir le Laboratoire</option>
                                        {laboratoriesResponse.data?.data?.laboratories?.docs?.map((lab, index) =>
                                            <option key={index} className="select-option" value={lab.uuid}>{lab.laboratoryName}</option>
                                        )}
                                    </Form.Control>
                                </Form.Group>
                            </Row>

                            <Row className='text-center m-t-20'>
                                <Col>
                                    <Button
                                        className="btn-success text-capitalize"
                                        style={{ marginRight: 0 }}
                                        color="primary"
                                        onClick={() => validateSwitchAndRefresh()}
                                        disabled={!selectedLabo}
                                    >
                                        Valider le choix
                                    </Button>
                                </Col>
                            </Row>

                            <br />
                        </div>
                    </Col>
                </Row>
            </ReUsableModal>
        </React.Fragment >
    );
};

export default SwitchLabModal;
