/**
 * HERE WILL BE ADDED ANY UTILITY FUNCTION NEED
 */

import { examsWithParamsLikedToLeucocytes } from '../config/constant';
import { getAgeInMonths, getAgeInYears } from './dates';
import { getApproximateHeight } from './getHeightFromAgeAndSex';

/**
 * Add spaces after each three digits of the number nbr
 * @param {*} nbr
 */
export const numberWithSpaces = (nbr) => {
    if (nbr === null || nbr === undefined) nbr = '';
    let parts = nbr.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
};

/**
 * @description determine if an array contains one or more items from another array.
 * @param {array} haystack the array to search.
 * @param {array} arr the array providing items to check for in the haystack.
 * @return {boolean} true|false if haystack contains at least one item from arr.
 */
export const containsElements = (haystack, arr) => {
    return arr.some((v) => haystack?.includes(v));
};

/**
 *
 * @param {*} string
 * @returns a string where only the first letter has been capitalized, the rest of the string is to lowercase
 */

export const capitalizeFirstLetter = (string) => {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 *
 * @param {*} string
 * @returns Returns a string where all the accents have been removed Ex: élève  => eleve
 */
export const removeAccents = (string) => {
    return string.normalize('NFD').replace(/\p{Diacritic}/gu, '');
};

// const r = new IntersectionObserver

export const calculateGeneratedParams = (filledParameters, currentPatient, currentExam) => {
    let biliTotale,
        biliDirecte,
        calcium,
        albumine,
        transferrine,
        ferSerique,
        creatinine,
        cholesterolTotal,
        cholesterolHDL,
        triglyceride,
        ASAT,
        ALAT,
        PSAl,
        PSAt,
        dosage,
        diurese,
        U,
        V,
        P;
    //SPECIFIC TO NFS
    let leucocytes = 0,
        polyNeutrophiles = 0,
        polyEosinophiles = 0,
        polyBasophiles = 0,
        lymphocytes = 0,
        monocytes = 0,
        blastes = 0,
        promyelocytes = 0,
        myeloEosinophiles = 0,
        myeloNeutrophiles = 0,
        metaEosinophiles = 0,
        metaNeutrophiles = 0,
        atypicCells = 0;

    filledParameters.map((filledParam, index) => {
        /*-------- BILIRUBINE INDIRECTE --------------- */
        if (currentExam.abbreviation.toLowerCase() === 'BILI T/L'.toLowerCase()) {
            if (filledParam.parameterName.toLowerCase().includes('totale')) biliTotale = filledParam.resultValue;
            if (filledParam.parameterName.toLowerCase().includes(' directe')) biliDirecte = filledParam.resultValue;
            if (filledParam.associatedFunction === 'bilirubineIndirecte') filledParameters[index].resultValue = biliTotale - biliDirecte;
        }

        /*-------- CALCIUM CORRIGÉ --------------- */
        if (currentExam.abbreviation.toLowerCase() === 'CA corr'.toLowerCase()) {
            if (filledParam.parameterName.toLowerCase().includes('calcium') && !filledParam.parameterName.toLowerCase().includes('corrig'))
                calcium = filledParam.resultValue * (filledParam.conversionFactor || 1);
            if (filledParam.parameterName.toLowerCase().includes('albumine')) albumine = filledParam.resultValue;
            if (filledParam.associatedFunction === 'correctedCalcium')
                filledParameters[index].resultValue = (calcium + (40 - albumine) / 40).toFixed(3).replace(/([^.])0+$/, '$1');
        }

        /*-------- Capacité totale de Fixation --------------- */
        if (currentExam.abbreviation.toLowerCase() === 'TST/COEF SAT'.toLowerCase()) {
            if (filledParam.parameterName.toLowerCase() === 'transferrine') transferrine = filledParam.resultValue;
            if (filledParam.associatedFunction === 'ctf')
                filledParameters[index].resultValue = (transferrine * 25).toFixed(3).replace(/([^.])0+$/, '$1');
        }

        /*-------- Coefficient de saturation en fer --------------- */
        if (currentExam.abbreviation.toLowerCase() === 'TST/COEF SAT'.toLowerCase()) {
            if (
                filledParam.parameterName.toLowerCase().includes('fer') &&
                removeAccents(filledParam.parameterName.toLowerCase()).includes('serique')
            )
                ferSerique = filledParam.resultValue * (filledParam.conversionFactor || 1);
            if (filledParam.associatedFunction === 'css')
                filledParameters[index].resultValue = ((ferSerique / (transferrine * 25)) * 100).toFixed(3).replace(/([^.])0+$/, '$1');
        }

        /*-------- Clairance de la créatinine (MDRD) --------------- */
        if (currentExam.abbreviation.toLowerCase() === 'CREAT-S'.toLowerCase()) {
            if (
                filledParam.parameterName.toLowerCase().includes('créatinine') &&
                !filledParam.parameterName.toLowerCase().includes('clairance')
            )
                creatinine = filledParam.resultValue * (filledParam.conversionFactor || 1);
            const ageInYears = getAgeInYears(currentPatient?.dateOfBirth);
            if (filledParam.associatedFunction === 'clairanceMDRD') {
                let result = 0;
                if (ageInYears < 21) {
                    const ageInMonths = getAgeInMonths(currentPatient?.dateOfBirth);
                    result = (36.5 * getApproximateHeight(ageInMonths, currentPatient?.sex)) / (creatinine); //We use the value in micromol/L
                } else {
                    result = 186.6 * (creatinine / 88.4) ** -1.154 * getAgeInYears(currentPatient?.dateOfBirth) ** -0.203 * 1.212;
                    if (currentPatient?.sex === 'F') result = result * 0.742;
                }
                filledParameters[index].resultValue = result.toFixed(3).replace(/([^.])0+$/, '$1');
            }
        }

        if (currentExam.abbreviation.toLowerCase() === 'BILAN LIP'.toLowerCase()) {
            //Should have the abbreviation normally
            /*-------- LDL Cholesterol --------------- */
            if (
                removeAccents(filledParam.parameterName.toLowerCase()).includes('cholesterol') &&
                filledParam.parameterName.toLowerCase().includes('total')
            )
                cholesterolTotal = filledParam.resultValue;
            if (
                removeAccents(filledParam.parameterName.toLowerCase()).includes('cholesterol') &&
                filledParam.parameterName.toLowerCase().includes('hdl')
            )
                cholesterolHDL = filledParam.resultValue;
            if (removeAccents(filledParam.parameterName.toLowerCase()).includes('triglycerides')) triglyceride = filledParam.resultValue;
            if (filledParam.associatedFunction === 'LDLCholesterol')
                filledParameters[index].resultValue = (cholesterolTotal - cholesterolHDL - triglyceride / 5)
                    .toFixed(3)
                    .replace(/([^.])0+$/, '$1');

            /*-------- Indice d'athérogénicité --------------- */
            if (filledParam.associatedFunction === 'indiceAtherogenicite')
                filledParameters[index].resultValue = (cholesterolTotal / cholesterolHDL).toFixed(3).replace(/([^.])0+$/, '$1');
        }

        /*-------- Rapport ALAT/ASAT --------------- */
        if (currentExam.abbreviation.toLowerCase() === 'ASAT/ALAT'.toLowerCase()) {
            if (filledParam.parameterName.toLowerCase().includes('asat')) ASAT = filledParam.resultValue;
            if (filledParam.parameterName.toLowerCase().includes('alat')) ALAT = filledParam.resultValue;
            if (filledParam.associatedFunction === 'ratioAlatAsat')
                filledParameters[index].resultValue = (ALAT / ASAT).toFixed(3).replace(/([^.])0+$/, '$1');
        }

        /*-------- Rapport PSAl/PSAt --------------- */
        if (currentExam.abbreviation.toLowerCase() === 'PSA t+l'.toLowerCase()) {
            if (filledParam.parameterName.toLowerCase() === 'PSA T'.toLowerCase()) PSAt = parseFloat(filledParam.resultValue);
            if (filledParam.parameterName.toLowerCase() === 'PSA L'.toLowerCase()) PSAl = parseFloat(filledParam.resultValue);
            if (filledParam.associatedFunction === 'ratioPSAlPSAt')
                filledParameters[index].resultValue = ((PSAl * 100) / PSAt).toFixed(3).replace(/([^.])0+$/, '$1');
        }

        /*-------- Résultat sur 24H --------------- */
        if (
            filledParam.parameterName.toLowerCase().includes('dosage') ||
            removeAccents(filledParam.parameterName.toLowerCase()).includes('echantillon')
        )
            dosage = filledParam.resultValue;
        if (removeAccents(filledParam.parameterName.toLowerCase()).includes('diurese')) diurese = filledParam.resultValue;
        if (filledParam.associatedFunction === 'resultat24H')
            filledParameters[index].resultValue = (dosage * diurese).toFixed(3).replace(/([^.])0+$/, '$1');

        /*-------- Clairance Créatinine --------------- */
        if (currentExam.examName.toLowerCase() === 'CLAIRANCE DE LA CREATININE'.toLowerCase()) {
            if (filledParam.parameterName.toLowerCase().includes('créatinine urinaire'))
                U = filledParam.resultValue * (filledParam.conversionFactor || 1);
            if (filledParam.parameterName.toLowerCase().includes('débit urinaire')) V = filledParam.resultValue;
            if (filledParam.parameterName.toLowerCase().includes('créatinine plasmatique'))
                P = filledParam.resultValue * (filledParam.conversionFactor || 1);
            if (filledParam.associatedFunction === 'clairanceCreatinine')
                filledParameters[index].resultValue = ((U * 1000 * V) / (P * 1440)).toFixed(3).replace(/([^.])0+$/, '$1');
        }

        /*-------- Hemogramme Complet NFS --------------- */
        if (currentExam.abbreviation.toLowerCase() === 'NFS'.toLowerCase()) {
            if (filledParam.parameterName.toLowerCase() === 'leucocytes') leucocytes = parseFloat(filledParam.resultValue);
            if (
                filledParam.parameterName.toLowerCase() === 'Polynucléaires Neutrophiles'.toLowerCase() &&
                !filledParam.isLinkedToLeucocytes
            )
                polyNeutrophiles = parseFloat(filledParam.resultValue) || 0;
            if (filledParam.parameterName.toLowerCase() === 'Polynucléaires Neutrophiles'.toLowerCase() && filledParam.isLinkedToLeucocytes)
                filledParameters[index].resultValue = ((polyNeutrophiles * leucocytes) / 100).toFixed(3).replace(/([^.])0+$/, '$1');

            if (
                filledParam.parameterName.toLowerCase() === 'Polynucléaires Eosinophiles'.toLowerCase() &&
                !filledParam.isLinkedToLeucocytes
            )
                polyEosinophiles = parseFloat(filledParam.resultValue) || 0;
            if (filledParam.parameterName.toLowerCase() === 'Polynucléaires Eosinophiles'.toLowerCase() && filledParam.isLinkedToLeucocytes)
                filledParameters[index].resultValue = ((polyEosinophiles * leucocytes) / 100).toFixed(3).replace(/([^.])0+$/, '$1');

            if (filledParam.parameterName.toLowerCase() === 'Polynucléaires Basophiles'.toLowerCase() && !filledParam.isLinkedToLeucocytes)
                polyBasophiles = parseFloat(filledParam.resultValue) || 0;
            if (filledParam.parameterName.toLowerCase() === 'Polynucléaires Basophiles'.toLowerCase() && filledParam.isLinkedToLeucocytes)
                filledParameters[index].resultValue = ((polyBasophiles * leucocytes) / 100).toFixed(3).replace(/([^.])0+$/, '$1');

            if (filledParam.parameterName.toLowerCase() === 'Lymphocytes'.toLowerCase() && !filledParam.isLinkedToLeucocytes)
                lymphocytes = parseFloat(filledParam.resultValue) || 0;
            if (filledParam.parameterName.toLowerCase() === 'Lymphocytes'.toLowerCase() && filledParam.isLinkedToLeucocytes)
                filledParameters[index].resultValue = ((lymphocytes * leucocytes) / 100).toFixed(3).replace(/([^.])0+$/, '$1');

            if (filledParam.parameterName.toLowerCase() === 'Monocytes'.toLowerCase() && !filledParam.isLinkedToLeucocytes)
                monocytes = parseFloat(filledParam.resultValue) || 0;
            if (filledParam.parameterName.toLowerCase() === 'Monocytes'.toLowerCase() && filledParam.isLinkedToLeucocytes)
                filledParameters[index].resultValue = ((monocytes * leucocytes) / 100).toFixed(3).replace(/([^.])0+$/, '$1');

            if (filledParam.parameterName.toLowerCase() === 'Blastes'.toLowerCase() && !filledParam.isLinkedToLeucocytes)
                blastes = parseFloat(filledParam.resultValue) || 0;
            if (filledParam.parameterName.toLowerCase() === 'Blastes'.toLowerCase() && filledParam.isLinkedToLeucocytes)
                filledParameters[index].resultValue = ((blastes * leucocytes) / 100).toFixed(3).replace(/([^.])0+$/, '$1');

            if (filledParam.parameterName.toLowerCase() === 'Promyélocytes'.toLowerCase() && !filledParam.isLinkedToLeucocytes)
                promyelocytes = parseFloat(filledParam.resultValue) || 0;
            if (filledParam.parameterName.toLowerCase() === 'Promyélocytes'.toLowerCase() && filledParam.isLinkedToLeucocytes)
                filledParameters[index].resultValue = ((promyelocytes * leucocytes) / 100).toFixed(3).replace(/([^.])0+$/, '$1');

            if (filledParam.parameterName.toLowerCase() === 'Myélocytes Eosinophiles'.toLowerCase() && !filledParam.isLinkedToLeucocytes)
                myeloEosinophiles = parseFloat(filledParam.resultValue) || 0;
            if (filledParam.parameterName.toLowerCase() === 'Myélocytes Eosinophiles'.toLowerCase() && filledParam.isLinkedToLeucocytes)
                filledParameters[index].resultValue = ((myeloEosinophiles * leucocytes) / 100).toFixed(3).replace(/([^.])0+$/, '$1');

            if (filledParam.parameterName.toLowerCase() === 'Myélocytes Neutrophiles'.toLowerCase() && !filledParam.isLinkedToLeucocytes)
                myeloNeutrophiles = parseFloat(filledParam.resultValue) || 0;
            if (filledParam.parameterName.toLowerCase() === 'Myélocytes Neutrophiles'.toLowerCase() && filledParam.isLinkedToLeucocytes)
                filledParameters[index].resultValue = ((myeloNeutrophiles * leucocytes) / 100).toFixed(3).replace(/([^.])0+$/, '$1');

            if (
                filledParam.parameterName.toLowerCase() === 'Métamyélocytes Eosinophiles'.toLowerCase() &&
                !filledParam.isLinkedToLeucocytes
            )
                metaEosinophiles = parseFloat(filledParam.resultValue) || 0;
            if (filledParam.parameterName.toLowerCase() === 'Métamyélocytes Eosinophiles'.toLowerCase() && filledParam.isLinkedToLeucocytes)
                filledParameters[index].resultValue = ((metaEosinophiles * leucocytes) / 100).toFixed(3).replace(/([^.])0+$/, '$1');

            if (
                filledParam.parameterName.toLowerCase() === 'Métamyélocytes Neutrophiles'.toLowerCase() &&
                !filledParam.isLinkedToLeucocytes
            )
                metaNeutrophiles = parseFloat(filledParam.resultValue) || 0;
            if (filledParam.parameterName.toLowerCase() === 'Métamyélocytes Neutrophiles'.toLowerCase() && filledParam.isLinkedToLeucocytes)
                filledParameters[index].resultValue = ((metaNeutrophiles * leucocytes) / 100).toFixed(3).replace(/([^.])0+$/, '$1');

            if (filledParam.parameterName.toLowerCase() === 'Formule leucocytaire'.toLowerCase()) {
                const sum =
                    polyNeutrophiles +
                    polyEosinophiles +
                    polyBasophiles +
                    lymphocytes +
                    monocytes +
                    blastes +
                    promyelocytes +
                    myeloEosinophiles +
                    myeloNeutrophiles +
                    metaEosinophiles +
                    metaNeutrophiles;

                filledParameters[index].resultValue = sum;

                if (sum !== 100) {
                    filledParameters[index].incorretValue = true;
                } else {
                    filledParameters[index].incorretValue = false;
                }
            }
        }

        //BACTERIOLOGICAL EXAMS INCLUDING CALCULATIONS ON LEUCOCYTES
        if (examsWithParamsLikedToLeucocytes.includes(currentExam.abbreviation)) {
            if (filledParam.parameterName.toLowerCase() === 'leucocytes') leucocytes = parseFloat(filledParam.resultValue);

            if (
                filledParam.parameterName.toLowerCase() === 'Polynucléaires Neutrophiles'.toLowerCase() &&
                !filledParam.isLinkedToLeucocytes
            )
                polyNeutrophiles = parseFloat(filledParam.resultValue) || 0;
            if (filledParam.parameterName.toLowerCase() === 'Polynucléaires Neutrophiles'.toLowerCase() && filledParam.isLinkedToLeucocytes)
                filledParameters[index].resultValue = ((polyNeutrophiles * leucocytes) / 100).toFixed(3).replace(/([^.])0+$/, '$1');

            if (filledParam.parameterName.toLowerCase() === 'Lymphocytes'.toLowerCase() && !filledParam.isLinkedToLeucocytes)
                lymphocytes = parseFloat(filledParam.resultValue) || 0;
            if (filledParam.parameterName.toLowerCase() === 'Lymphocytes'.toLowerCase() && filledParam.isLinkedToLeucocytes)
                filledParameters[index].resultValue = ((lymphocytes * leucocytes) / 100).toFixed(3).replace(/([^.])0+$/, '$1');

            if (filledParam.parameterName.toLowerCase() === 'Cellules atypiques'.toLowerCase() && !filledParam.isLinkedToLeucocytes)
                atypicCells = parseFloat(filledParam.resultValue) || 0;
            if (filledParam.parameterName.toLowerCase() === 'Cellules atypiques'.toLowerCase() && filledParam.isLinkedToLeucocytes)
                filledParameters[index].resultValue = ((atypicCells * leucocytes) / 100).toFixed(3).replace(/([^.])0+$/, '$1');
        }

        // return filledParameters;
    });

    return filledParameters;
};
