import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';

const ReUsableModal = (props) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);


    const handleClose = () => {
        props.setShowStateOnClose(false);
        setShow(false)
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop={props.closeWhenClickOutside || "static"}
            keyboard={false}
            size={props.size}
        >
            <Modal.Header closeButton>
                <Modal.Title as='h4'>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
            {/* <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary">Understood</Button>
            </Modal.Footer> */}
        </Modal>
    );
}

export default ReUsableModal;