import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import { persistStore } from 'redux-persist';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import reducers from './reducers';

// const nonSerializableCheck = createSerializableStateInvariantMiddleware({
//   isSerializable: value => {
//     if (typeof value === 'function' && value.name === 'register') {
//       return true;
//     }
//     return false;
//   },
// });

const store = configureStore({
    reducer: reducers,
    // middleware: [nonSerializableCheck],
    middleware: getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['persist/PERSIST']
        }
      }),
    devTools: true
});

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

const persister = persistStore(store);

export { store, persister };
