import React from 'react';

const Spinner = () => {
    return (
        <div
            style={{
                display: 'block',
                position: 'fixed',
                right: -40,
                top: 10,
                zIndex: 1031
            }}
        >
            <div
            // style={{
            //     // animation: '1000ms linear infinite spinner',
            //     animation: 'spin',
            //     borderBottom: '2px solid transparent',
            //     borderLeft: '2px solid #29d',
            //     borderRadius: '50%',
            //     borderRight: '2px solid transparent',
            //     borderTop: '2px solid #29d',
            //     boxSizing: 'border-box',
            //     animationIterationCount: "infinite",
            //     animationTimingFunction: "lianear",
            //     animationDuration: "5000ms",
            //     animationName: `spin {
            //         from {
            //             transform:rotate(0deg);
            //         }
            //         to {
            //             transform:rotate(90deg);
            //         }
            //     }`,
            //     height: 23,
            //     width: 23
            // }}
            >
                <svg
                    width="90"
                    height="65"
                    viewBox="0 0 90 120"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="#44A0FB"
                >
                    <g fill="none" fillRule="evenodd" strokeWidth="5">
                        <circle cx="10" cy="35" r="2">
                            <animate
                                attributeName="r"
                                begin="0s"
                                dur="1s"
                                values="1; 35"
                                calcMode="spline"
                                keyTimes="0; 1"
                                keySplines="0.165, 0.84, 0.44, 1"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="stroke-opacity"
                                begin="0s"
                                dur="1s"
                                values="1; 0"
                                calcMode="spline"
                                keyTimes="0; 1"
                                keySplines="0.3, 0.61, 0.355, 1"
                                repeatCount="indefinite"
                            />
                        </circle>
                    </g>
                </svg>

            </div>
        </div>
    );
};

export default Spinner;
