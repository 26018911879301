export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';

export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_INITIALIZE = 'ACCOUNT_INITIALIZE';
export const UPDATE_SESSION_TIMEOUT = 'UPDATE_SESSION_TIMEOUT';

export const SET_SAMPLES_DATA = 'SET_SAMPLES_DATA';