import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListGroup, Dropdown, Media } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

import ChatList from './ChatList';
import { LOGOUT } from './../../../../store/actions';

// import avatar1 from '../../../../assets/images/user/avatar-1.jpg';
import avatar2 from '../../../../assets/images/user/avatar-2.jpg';
// import avatar3 from '../../../../assets/images/user/avatar-3.jpg';
// import avatar4 from '../../../../assets/images/user/avatar-4.jpg';
import { containsElements, numberWithSpaces } from '../../../../utils/otherUtilities';
import { Store } from 'react-notifications-component';
import { API_SERVER, STAFF_USER_TYPE, UsersRolesAttributes, notificationConfigs } from '../../../../config/constant';
import SwitchLabModal from './Modal/SwitchLabModal';
import axios from 'axios';
// import useSound from 'use-sound';
// import boopSfx from './alarm.wav';

const NavRight = () => {
    // const BoopButton = () => {
    // const [play] = useSound(boopSfx);

    // };
    const account = useSelector((state) => state.account);
    const dispatcher = useDispatch();

    const [listOpen, setListOpen] = useState(false);
    const [showLaboSwitchModal, setShowLaboSwitchModal] = useState(false);
    const [emergenciesAndDelayedTreatments, setEmergenciesAndDelayedTreatments] = useState({ totalEmergencies: 0, delayedTreatments: 0 });
    const [shouldShowAlerts, setShouldShowAlerts] = useState(false);

    const handleLogout = () => {
        Store.addNotification({
            ...notificationConfigs,
            title: 'Tchaou Tchaou!! 😎',
            message: 'On se capte plus tard!'
        });

        dispatcher({
            type: LOGOUT,
            payload: {
                isLoggedIn: false,
                user: null,
                token: {
                    value: '',
                    expiresDate: ''
                }
            }
        });
    };

    const fetchEmergenciesAndDelayedTreatments = async () => {
        return new Promise(async (resolve, reject) => {
            await axios
                .get(API_SERVER + 'dashboard/emergencies-and-delayed', {
                    params: {
                        laboratory: account.user?.laboratory
                    }
                })
                .then((res) => {
                    setEmergenciesAndDelayedTreatments(res.data);
                    if (account?.user?.roles?.length !== 0) {
                        if (res.data?.totalEmergencies > 0 || res.data?.totalDelayedTreatments > 0) {
                            setShouldShowAlerts(true);
                        }
                    }

                    resolve({ data: res.data });
                })
                .catch((err) => {
                    reject(err);
                    throw new Error(err);
                });
        });
    };

    useEffect(() => {
        // fetchEmergenciesAndDelayedTreatments();
    }, []);

    return (
        <React.Fragment>
            {/* <button onClick={play}>Boop!</button>; */}

            {showLaboSwitchModal && <SwitchLabModal account={account} setShowStateOnClose={(value) => setShowLaboSwitchModal(value)} />}

            <ListGroup
                as="ul"
                bsPrefix=" "
                className="navbar-nav ml-auto"
                style={{ backgroundColor: shouldShowAlerts && '#f44236' }}
                id="navbar-right"
            >
                <ListGroup.Item as="li" bsPrefix=" ">
                    <Dropdown>
                        <Dropdown.Toggle as={Link} variant="link" to="#" id="dropdown-basic ">
                            {shouldShowAlerts ? (
                                <span>
                                    <i className={`fas fa-bell icon ${shouldShowAlerts && 'text-white'}`} />
                                    <i className={`fas fa-bell icon ${shouldShowAlerts && 'text-white'}`} />
                                    <i className={`fas fa-bell icon ${shouldShowAlerts && 'text-white'}`} />
                                </span>
                            ) : (
                                <span>
                                    <i className={`fas fa-bell icon`} />
                                </span>
                            )}
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight className="notification notification-scroll">
                            <div className="noti-head">
                                <h6 className="d-inline-block m-b-0">Notifications Importantes</h6>
                                <div className="float-right">
                                    {/* <Link to="#" className="m-r-10">
                                        mark as read
                                    </Link>
                                    <Link to="#">clear all</Link> */}
                                </div>
                            </div>
                            <PerfectScrollbar>
                                <ListGroup as="ul" bsPrefix=" " variant="flush" className="noti-body">
                                    {/* <ListGroup.Item as="li" bsPrefix=" " className="n-title">
                                        <p className="m-b-0">NEW</p>
                                    </ListGroup.Item> */}
                                    {emergenciesAndDelayedTreatments.totalEmergencies > 0 && (
                                        <Link to="/technique/units">
                                            <ListGroup.Item as="li" bsPrefix=" " className="notification">
                                                <Media>
                                                    {/* <img className="img-radius" src={avatar1} alt="Generic placeholder" /> */}
                                                    <Media.Body>
                                                        <p>
                                                            <h4 className="text-c-red">
                                                                <b>
                                                                    {numberWithSpaces(emergenciesAndDelayedTreatments.totalEmergencies)}{' '}
                                                                    Urgences
                                                                </b>
                                                            </h4>
                                                            <span className="n-time text-muted">
                                                                <i className="icon feather icon-clock danger m-r-10" />
                                                                {/* 30 min */}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            {numberWithSpaces(emergenciesAndDelayedTreatments.totalEmergencies) +
                                                                ' prescriptions URGENTES non prélevées ou non traitées'}
                                                        </p>
                                                    </Media.Body>
                                                </Media>
                                            </ListGroup.Item>
                                        </Link>
                                    )}
                                    {/* <hr/>    */}
                                    {emergenciesAndDelayedTreatments.totalDelayedTreatments > 0 && (
                                        <Link to="/technique/units">
                                            <ListGroup.Item as="li" bsPrefix=" " className="notification">
                                                <Media>
                                                    {/* <img className="img-radius" src={avatar1} alt="Generic placeholder" /> */}
                                                    <Media.Body>
                                                        <p>
                                                            <h4 className="text-c-red">
                                                                <b>
                                                                    {numberWithSpaces(
                                                                        emergenciesAndDelayedTreatments.totalDelayedTreatments
                                                                    )}{' '}
                                                                    Traitements en Retard
                                                                </b>
                                                            </h4>
                                                            <span className="n-time text-muted">
                                                                <i className="icon feather icon-clock danger m-r-10" />
                                                                {/* 30 min */}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            {numberWithSpaces(emergenciesAndDelayedTreatments.totalDelayedTreatments) +
                                                                ' prescriptions dont le délai est proche ou dépassé'}
                                                        </p>
                                                    </Media.Body>
                                                </Media>
                                            </ListGroup.Item>
                                        </Link>
                                    )}
                                    {/* <ListGroup.Item as="li" bsPrefix=" " className="n-title">
                                        <p className="m-b-0">EARLIER</p>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" bsPrefix=" " className="notification">
                                        <Media>
                                            <img className="img-radius" src={avatar2} alt="Generic placeholder" />
                                            <Media.Body>
                                                <p>
                                                    <strong>Joseph William</strong>
                                                    <span className="n-time text-muted">
                                                        <i className="icon feather icon-clock m-r-10" />
                                                        30 min
                                                    </span>
                                                </p>
                                                <p>Purchase New Theme and make payment</p>
                                            </Media.Body>
                                        </Media>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" bsPrefix=" " className="notification">
                                        <Media>
                                            <img className="img-radius" src={avatar3} alt="Generic placeholder" />
                                            <Media.Body>
                                                <p>
                                                    <strong>Sara Soudein</strong>
                                                    <span className="n-time text-muted">
                                                        <i className="icon feather icon-clock m-r-10" />
                                                        30 min
                                                    </span>
                                                </p>
                                                <p>currently login</p>
                                            </Media.Body>
                                        </Media>
                                    </ListGroup.Item>
                                    <ListGroup.Item as="li" bsPrefix=" " className="notification">
                                        <Media>
                                            <img className="img-radius" src={avatar4} alt="Generic placeholder" />
                                            <Media.Body>
                                                <p>
                                                    <strong>Suzen</strong>
                                                    <span className="n-time text-muted">
                                                        <i className="icon feather icon-clock m-r-10" />
                                                        yesterday
                                                    </span>
                                                </p>
                                                <p>Purchase New Theme and make payment</p>
                                            </Media.Body>
                                        </Media>
                                    </ListGroup.Item> */}
                                </ListGroup>
                            </PerfectScrollbar>
                            {/* <div className="noti-footer">
                                <Link to="#">show all</Link>
                            </div> */}
                        </Dropdown.Menu>
                    </Dropdown>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                    <Dropdown>
                        <Dropdown.Toggle
                            as={Link}
                            variant="link"
                            to="#"
                            className="displayChatbox primary"
                            onClick={() => setListOpen(true)}
                        >
                            {/* <i className="feather icon-folder danger" /> */}
                            {account.user?.userType === STAFF_USER_TYPE ? (
                                <i className="feather icon-zap f-20 text-c-blue" />
                            ) : (
                                <i className="far fa-comment f-20" />
                            )}
                        </Dropdown.Toggle>
                    </Dropdown>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                    <Dropdown className="drp-user">
                        <Dropdown.Toggle as={Link} variant="link" to="#" id="dropdown-basic">
                            <i className={`icon feather icon-settings ${shouldShowAlerts && 'text-white'}`} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu alignRight className="profile-notification">
                            <div className="pro-head">
                                <img src={avatar2} className="img-radius" alt="User Profile" />
                                <span>{account?.user?.fullName}</span>
                                <Link to="#" className="dud-logout" onClick={handleLogout} title="Logout">
                                    <i className="feather icon-log-out" />
                                </Link>
                            </div>
                            <ListGroup as="ul" bsPrefix=" " variant="flush" className="pro-body">
                                <ListGroup.Item as="li" bsPrefix=" ">
                                    <Link to="/results" className="dropdown-item">
                                        <i className="feather icon-file" />
                                        <b>Mes Résultats</b>
                                    </Link>
                                </ListGroup.Item>
                                {containsElements(account?.user?.roles, [UsersRolesAttributes.SWITCH_LAB]) && (
                                    <ListGroup.Item as="li" bsPrefix=" " onClick={() => setShowLaboSwitchModal(true)}>
                                        <Link to="#" className="dropdown-item">
                                            <b>
                                                <i className="fas fa-exchange-alt" />
                                                Changer de Laboratoire
                                            </b>
                                        </Link>
                                    </ListGroup.Item>
                                )}
                                <ListGroup.Item as="li" bsPrefix=" ">
                                    <Link to={`/users/${account?.user?.uuid}/profile`} className="dropdown-item">
                                        <b>
                                            <i className="feather icon-user" />
                                            Mes Informations{' '}
                                        </b>
                                    </Link>
                                </ListGroup.Item>
                                {/* <ListGroup.Item as="li" bsPrefix=" ">
                                    <Link to="#" className="dropdown-item">
                                        <i className="feather icon-mail" /> My Messages
                                    </Link>
                                </ListGroup.Item> */}
                                {/* <ListGroup.Item as="li" bsPrefix=" ">
                                    <Link to="#" className="dropdown-item">
                                        <i className="feather icon-lock" /> Lock Screen
                                    </Link>
                                </ListGroup.Item> */}
                                <ListGroup.Item as="li" bsPrefix=" ">
                                    <Link to="#" className="dropdown-item" onClick={handleLogout}>
                                        <b>
                                            <i className="feather icon-log-out" /> Se Déconnecter{' '}
                                        </b>
                                    </Link>
                                </ListGroup.Item>
                            </ListGroup>
                        </Dropdown.Menu>
                    </Dropdown>
                </ListGroup.Item>
            </ListGroup>
            <ChatList listOpen={listOpen} closed={() => setListOpen(false)} />
        </React.Fragment>
    );
};

export default NavRight;
