import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import routes, { renderRoutes } from './routes';
import { BASENAME } from './config/constant';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import {
    QueryClient,
    QueryClientProvider,
} from 'react-query';

// Create a client
const queryClient = new QueryClient();

const App = () => {
    return (
        <React.Fragment>
            <QueryClientProvider client={queryClient}>
                <ReactNotifications />
                <Router basename={BASENAME}>{renderRoutes(routes)}</Router>
            </QueryClientProvider>
        </React.Fragment>
    );
};

export default App;
