import React, { useContext, useState } from 'react';

import { ConfigContext } from '../../../contexts/ConfigContext';
import useWindowSize from '../../../hooks/useWindowSize';

import NavLogo from './NavLogo';
import NavContent from './NavContent';
import { useSelector } from 'react-redux';
import { getMenuItems } from '../../../menu-items';
// import navigation from '../../../menu-items';

const Navigation = () => {
    const account = useSelector((state) => state.account);
    const configContext = useContext(ConfigContext);
    const { collapseMenu } = configContext.state;
    const windowSize = useWindowSize();
    const [menuItems] = useState(getMenuItems(account?.user?.roles));
    // console.log({ account });
    // const [menuItems, setMenuItems] = useState(navigation.items);

    // const menuItems = getMenuItems(account?.user?.roles);
    // let menuItems = [] ;

    // useEffect(() => {
    //     menuItems = getMenuItems(account?.user?.roles);
    //     console.log({ menuItems });
    // }, [account]);

    let navClass = [
        'pcoded-navbar',
        'menupos-static',
        'menu-dark',
        'navbar-default',
        'brand-default',
        'drp-icon-style1',
        'menu-item-icon-style1',
        'active-default',
        'title-default'
    ];

    if (windowSize.width < 992 && collapseMenu) {
        navClass = [...navClass, 'mob-open'];
    } else if (collapseMenu) {
        navClass = [...navClass, 'navbar-collapsed'];
    }

    let images = [];

    for (let i = 1; i <= 37; i++) {
        images = [...images, `phone${i}.jpg`];
    }

    // const images = [
    //     'phone1.jpg',
    //     'phone2.jpg',
    //     'phone3.jpg',
    //     'phone4.jpg',
    //     'phone5.jpg',
    //     'phone6.jpg',
    //     'phone7.jpg',
    //     'phone8.jpg',
    //     'phone9.jpg',
    //     'phone10.jpg',
    //     'phone11.jpg',
    //     'phone12.jpg',
    //     'phone15.jpg',
    //     'phone16.jpg',
    //     'phone17.jpg',
    // ]

    let now = Date.now();
    let lastDate = localStorage.getItem("lastWallPaperChange");
    let lastImage = localStorage.getItem("lastWallPaper");
    let diffMs = (now - lastDate);
    let diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
    let chosenImage = images[3];

    if (!lastDate) {
        localStorage.setItem("lastWallPaperChange", now);
        localStorage.setItem("lastWallPaper", chosenImage);
    } else {
        if (diffMins >= 30) { //After each 30 minutes, the background image is changed
            localStorage.setItem("lastWallPaperChange", now);
            chosenImage = images[Math.floor(Math.random() * images.length)];
            localStorage.setItem("lastWallPaper", chosenImage);
        }
    }

    let navStyle = {
        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(/' + lastImage + ')',
        // background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(/' + "phone2.jpg" + ')',
        backgroundAttachment: 'fixed',
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        backgroundRepeat: "no-repeat",
    };

    let navBarClass = ['navbar-wrapper'];

    let navContent = (
        <div className={navBarClass.join(' ')}>
            <NavLogo />
            <NavContent navigation={menuItems} />
        </div>
    );
    if (windowSize.width < 992) {
        navContent = (
            <div className="navbar-wrapper">
                <NavLogo />
                <NavContent navigation={menuItems} />
            </div>
        );
    }
    return (
        <React.Fragment>
            <nav className={navClass.join(' ')} style={navStyle}>
                {navContent}
            </nav>
        </React.Fragment>
    );
};

export default Navigation;