const heightData = {
    F: [
      { age: 0, height: 50 },
      { age: 6, height: 67 },
      { age: 12, height: 78 },
      { age: 18, height: 79 },
      { age: 24, height: 84 },
      { age: 30, height: 90 },
      { age: 36, height: 93 },
      { age: 42, height: 95 },
      { age: 48, height: 101 },
      { age: 54, height: 103 },
      { age: 60, height: 115 },
      { age: 66, height: 115 },
      { age: 72, height: 118 },
      { age: 78, height: 125.7 },
      { age: 84, height: 126 },
      { age: 90, height: 127.5 },
      { age: 96, height: 131.2 },
      { age: 102, height: 136.3 },
      { age: 108, height: 137 },
      { age: 114, height: 138 },
      { age: 120, height: 142 },
      { age: 126, height: 148.3 },
      { age: 132, height: 150 },
      { age: 138, height: 151.5 },
      { age: 144, height: 153.2 },
      { age: 150, height: 157 },
      { age: 156, height: 158.4 },
      { age: 162, height: 160.5 },
      { age: 168, height: 161.2 },
      { age: 174, height: 162 },
      { age: 180, height: 162.5 },
      { age: 186, height: 163.7 },
      { age: 192, height: 163 },
      { age: 198, height: 163 },
      { age: 216, height: 163 },
      { age: 222, height: 163 },
      { age: 228, height: 163.5 },
      { age: 234, height: 164 },
      { age: 240, height: 164 },
      { age: 246, height: 164.6 },
      { age: 252, height: 165 }
    ],
    H: [
      { age: 0, height: 50 },
      { age: 6, height: 67 },
      { age: 12, height: 78 },
      { age: 18, height: 81 },
      { age: 24, height: 86 },
      { age: 30, height: 92 },
      { age: 36, height: 94 },
      { age: 42, height: 96 },
      { age: 48, height: 102 },
      { age: 54, height: 104 },
      { age: 60, height: 115.1 },
      { age: 66, height: 115.3 },
      { age: 72, height: 115.5 },
      { age: 78, height: 119 },
      { age: 84, height: 126.6 },
      { age: 90, height: 127 },
      { age: 96, height: 128 },
      { age: 102, height: 133.4 },
      { age: 108, height: 138.1 },
      { age: 114, height: 138.5 },
      { age: 120, height: 138.5 },
      { age: 126, height: 144.2 },
      { age: 132, height: 146.8 },
      { age: 138, height: 149 },
      { age: 144, height: 149 },
      { age: 150, height: 155.3 },
      { age: 156, height: 156 },
      { age: 162, height: 161.7 },
      { age: 168, height: 164 },
      { age: 174, height: 167.1 },
      { age: 180, height: 169 },
      { age: 186, height: 171.2 },
      { age: 192, height: 173.5 },
      { age: 198, height: 174.7 },
      { age: 204, height: 175 },
      { age: 210, height: 175 },
      { age: 216, height: 176 },
      { age: 222, height: 176.5 },
      { age: 228, height: 176.5 },
      { age: 234, height: 177 },
      { age: 240, height: 177.2 },
      { age: 246, height: 178 }
    ]
  };
  
  export const getApproximateHeight = (ageInMonths, gender) => {
    const data = heightData[gender];
    if (!data) {
      throw new Error("Invalid gender specified. Use 'F' or 'M'.");
    }
  
    let lowerBound, upperBound;
    for (let i = 0; i < data.length - 1; i++) {
      if (data[i].age <= ageInMonths && ageInMonths <= data[i + 1].age) {
        lowerBound = data[i];
        upperBound = data[i + 1];
        break;
      }
    }
  
    if (!lowerBound || !upperBound) {
      throw new Error("Age is out of range.");
    }
  
    const ageRange = upperBound.age - lowerBound.age;
    const heightRange = upperBound.height - lowerBound.height;
    const ageProgress = (ageInMonths - lowerBound.age) / ageRange;
  
    const approximateHeight = lowerBound.height + ageProgress * heightRange;
    return approximateHeight.toFixed(1);
  };