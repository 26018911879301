import React, { useState, useEffect } from 'react';

import PendingPrescription from './PendingPrescription';
import PrescriptionDetails from './Chat';
import axios from 'axios';
import { API_SERVER } from '../../../../../../config/constant';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

const PendingPrescriptions = ({ listOpen, search }) => {
    const [chatOpen, setChatOpen] = useState(listOpen);
    const [isLoadisLoadingData, setIsLoadisLoadingData] = useState(false);
    const [tempPrescription, setTempPrescription] = useState({});
    const account = useSelector((state) => state.account);

    useEffect(() => {
        setChatOpen(false);
    }, [listOpen]);

    /**
    * 
    * @returns The list of the precription of the user whose uuid is in the url
    */
    const fetchTempPrescriptions = async () => {
        setIsLoadisLoadingData(true);
        return new Promise(async (resolve, reject) => {
            await axios
                .get(API_SERVER + 'users/patient/temp-prescriptions', {
                    params: {
                        search,
                        laboratory: account.user?.laboratory
                    }
                }).then((res) => {
                    setIsLoadisLoadingData(false);
                    resolve(res.data);
                }).catch((err) => {
                    setIsLoadisLoadingData(false);
                    reject(err);
                })
        });
    }

    const prescriptionsResponse = useQuery('getTempPrescriptions', fetchTempPrescriptions, {
        refetchOnWindowFocus: false,
        enabled: false
    });

    useEffect(() => {
        prescriptionsResponse.refetch();
    }, [search]);

    useEffect(() => {
        setChatOpen(false);
    }, [listOpen]);



    const pendingPrescriptionsList = prescriptionsResponse.data?.temporaryPrescriptions?.map((prescription) => {
        return (
            <PendingPrescription
                key={prescription.uuid}
                data={prescription}
                activeId={tempPrescription.uuid}
                clicked={() => {
                    setChatOpen(true);
                    setTempPrescription(prescription);
                }}
            />
        );
    });

    return (
        <React.Fragment>
            {isLoadisLoadingData && <h6 className='text-center'><i>Chargement...</i></h6>}
            {pendingPrescriptionsList}
            <PrescriptionDetails
                tempPrescription={tempPrescription}
                chatOpen={chatOpen}
                listOpen={listOpen}
                closed={() => {
                    setChatOpen(false);
                    setTempPrescription({});
                    prescriptionsResponse.refetch();
                }}
            />
        </React.Fragment>
    );
};

export default PendingPrescriptions;
