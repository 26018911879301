import React from 'react';
import { Badge, Media } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { monthDayYearAtTime } from '../../../../../../../utils/dates'
import { PrescriptionStatuses } from '../../../../../../../config/constant';

const images = require.context('../../../../../../../assets/images/user', true);

const PendingPrescription = ({ data, activeId, clicked }) => {
    // let photo = images(`./${data.photo}`);
    let timeClass = ['d-block'];
    if (data.status === PrescriptionStatuses.PENDING) {
        timeClass = [...timeClass, 'text-c-green'];
    } else {
        timeClass = [...timeClass, 'text-muted'];
    }

    let time = '';
    if (data.createdAt) {
        time = <small className={timeClass.join(' ')}><i>{monthDayYearAtTime(data.createdAt)}</i></small>;
    }

    let status = '';
    if (data.status) {
        status = <Badge className='badge-status p-l-10 p-r-10 p-t-5 p-b-5' variant={data.status === PrescriptionStatuses.PENDING ? "success" : "default"}>{data.status}</Badge>;
    }

    return (
        <React.Fragment>
            <Media className={activeId === data.uuid ? 'userlist-box active' : 'userlist-box'} onClick={clicked}>
                {/* <Link to="#" className="media-left">
                    {' '}
                    <img className="media-object img-radius" src={photo.default} alt={data.name} />
                    {data.status}
                </Link> */}
                <Media.Body>
                    <h6 className="chat-header">
                        {data.patient}
                    </h6>
                    <h6 className="chat-sub-header">
                        {data.prescriberName}
                        {status}
                        {time}
                    </h6>
                </Media.Body>
            </Media>
        </React.Fragment>
    );
};

export default PendingPrescription;
