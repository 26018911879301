import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, Alert } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import ReUsableModal from '../../components/ReUsableModal';
import axios from 'axios';
import useScriptRef from '../../hooks/useScriptRef';
import { API_SERVER, notificationConfigs } from '../../config/constant';
import { Store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { LOGOUT } from '../../store/actions';
import { useDispatch } from 'react-redux';
import OneSignal from 'react-onesignal';
// import runOneSignal from '../../utils/onesignal.utils';


const ResetPasswordModal = (props) => {
    // useEffect(async () => {
    //     await runOneSignal();
    //   })

    console.log("INITIALIZED")
    // OneSignal.init({ appId: 'b71de230-222e-4150-b8c5-1aac9ce1230b' }).then((r) => {
    //     // setInitialized(true);
    //     console.log("INITIIALIZED")
    //     console.log({r})
    //     OneSignal.Slidedown.promptPush();
    //     // do other stuff
    //   })

    // Initialize a boolean state
    const [passwordShown, setPasswordShown] = useState(false);
    const [userDevice, setUserDevice] = useState(OneSignal.User.PushSubscription.id);
    const { user } = props;

    console.log(OneSignal.User)
    console.log({userDevice})

    const scriptedRef = useScriptRef();
    const dispatcher = useDispatch();

    const logout = (hideNotification) => {
        if (!hideNotification) {
            Store.addNotification({
                ...notificationConfigs,
                title: 'Tchaou Tchaou!! 😎',
                message: 'On se capte plus tard!'
            });
        }
        dispatcher({
            type: LOGOUT,
            payload: {
                isLoggedIn: false,
                user: null,
                token: {
                    value: '',
                    expiresDate: ''
                }
            }
        });
    };
    return (
        <React.Fragment>
            <ReUsableModal setShowStateOnClose={(value) => logout()} show={true} title={`Modifez votre mot de passe`} size={'lg'}>
                <Row>
                    <Col xs={1}></Col>
                    <Col xs={10}>
                        {
                            <h4 className="text-center">
                                Bienvenue <b>{user.fullName}</b>
                            </h4>
                        }
                        <Alert variant="info">
                            Veuillez changer votre mot de passe afin qu'il vous soit personnel avant d'utiliser l'application. Vous serez
                            deconnecté et vous reconnecterez avec votre nouveau mot de passe.
                        </Alert>
                        <div>
                            <Formik
                                initialValues={{
                                    newPassword: '',
                                    submit: null
                                }}
                                validationSchema={Yup.object().shape({
                                    newPassword: Yup.string()
                                        .required('Entrez le mot de passe, SVP')
                                        .min(6, 'Mot de passe trop court - Doit contenir au moins 6 caractères.')
                                        // .matches(/[a-zA-Z0-9]/, 'Le mot de passe doit contenir des chiffres et des lettres.'),
                                        .matches(
                                            /(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d$#@!%*?&]{6,}$/,
                                            'Le mot de passe doit contenir des chiffres et des lettres.'
                                        ),
                                    passwordConfirmation: Yup.string()
                                        .required('Entrez à nouveu le mot de passe, SVP')
                                        .oneOf(
                                            [Yup.ref('newPassword'), null],
                                            'Hmm, les mots de passe ne sont pas identiques. Réessayez SVP'
                                        )
                                })}
                                onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                                    let dataToSend = {
                                        userId: user.uuid,
                                        newPassword: values.newPassword,
                                        userDevice
                                    };
                                    try {
                                        axios
                                            .post(API_SERVER + 'auth/reset-password', {
                                                ...dataToSend
                                            })
                                            .then((response) => {
                                                if (response.status === 201) {
                                                    Store.addNotification({
                                                        ...notificationConfigs,
                                                        message: 'Mot de passe modifié avec succès'
                                                    });
                                                    setStatus({ success: true });
                                                    setSubmitting(false);
                                                    logout(true);
                                                } else {
                                                    Store.addNotification({
                                                        ...notificationConfigs,
                                                        title: 'Erreur!',
                                                        message: 'Vérifiez les informations ou réessayez plus tard SVP!',
                                                        type: 'danger'
                                                    });
                                                    setStatus({ success: false });
                                                    setSubmitting(false);
                                                }
                                            })
                                            .catch((error) => {
                                                let message = 'Vérifiez les informations ou réessayez plus tard SVP!';
                                                Store.addNotification({
                                                    ...notificationConfigs,
                                                    title: 'Erreur!',
                                                    message: message,
                                                    type: 'danger'
                                                });
                                                setStatus({ success: false });
                                                setErrors({ submit: error.response.data.message });
                                                setSubmitting(false);
                                            });
                                    } catch (err) {
                                        if (scriptedRef.current) {
                                            setStatus({ success: false });
                                            setErrors({ submit: err.message });
                                            setSubmitting(false);
                                        }
                                    }
                                }}
                            >
                                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Form.Row>
                                            <Form.Group as={Col} controlId="formGridnewPassword">
                                                <Form.Label>Nouveau mot de Passe</Form.Label>
                                                <Form.Control
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={touched.newPassword && errors.newPassword}
                                                    value={values.newPassword}
                                                    name="newPassword"
                                                    type={passwordShown ? 'text' : 'password'}
                                                    placeholder=""
                                                    autoFocus
                                                    required
                                                />
                                                {touched.newPassword && errors.newPassword && (
                                                    <small className="text-danger form-text">{errors.newPassword}</small>
                                                )}
                                            </Form.Group>
                                        </Form.Row>

                                        <Form.Row>
                                            <Form.Group as={Col} controlId="formGridpasswordConfirmation">
                                                <Form.Label>Répétez le mot de Passe</Form.Label>
                                                <Form.Control
                                                    name="passwordConfirmation"
                                                    type={passwordShown ? 'text' : 'password'}
                                                    placeholder=""
                                                    required
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    error={touched.passwordConfirmation && errors.passwordConfirmation}
                                                    value={values.passwordConfirmation}
                                                />
                                                {touched.passwordConfirmation && errors.passwordConfirmation && (
                                                    <small className="text-danger form-text">{errors.passwordConfirmation}</small>
                                                )}
                                            </Form.Group>
                                        </Form.Row>

                                        <Row>
                                            <Col>
                                                <Button className="btn-danger float-left" onClick={() => logout()}>
                                                    Annuler
                                                </Button>
                                            </Col>
                                            <Col className="text-center">
                                                <Button
                                                    style={{ marginRight: 0 }}
                                                    onClick={() => setPasswordShown(!passwordShown)}
                                                    variant="info"
                                                >
                                                    <i className={passwordShown ? 'feather icon-lock' : 'feather icon-eye'} />
                                                    {(passwordShown ? 'Masquer' : 'Afficher') + ' la valeur'}
                                                </Button>
                                            </Col>
                                            <Col>
                                                <Button
                                                    className="text-capitalize float-right"
                                                    style={{ marginRight: 0 }}
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    type="submit"
                                                    variant="success"
                                                >
                                                    Valider
                                                </Button>
                                            </Col>
                                        </Row>
                                        {/* {/* </form> */}
                                    </Form>
                                )}
                            </Formik>
                            <br />
                        </div>
                    </Col>
                </Row>
            </ReUsableModal>
        </React.Fragment>
    );
};

export default ResetPasswordModal;
