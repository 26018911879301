import React, { useEffect, useState } from 'react';
import { FormControl, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

import PendingPrescriptions from './Friends';
import { useSelector } from 'react-redux';
import { API_SERVER, STAFF_USER_TYPE } from '../../../../../config/constant';
import axios from 'axios';
import { useQuery } from 'react-query';
import { numberWithSpaces } from '../../../../../utils/otherUtilities';

const ChatList = ({ listOpen, closed }) => {
    const account = useSelector((state) => state.account);
    const [search, setSearch] = useState('');
    let listClass = ['header-user-list'];
    if (listOpen) {
        listClass = [...listClass, 'open'];
    }

    const fetchLaboratory = () => {
        return new Promise(async (resolve, reject) => {
            axios
                .get(API_SERVER + 'admin/configs/labs/get-one', {
                    params: {
                        laboratory: account.user.laboratory
                    }
                })
                .then((res) => {
                    resolve(res.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    };

    const laboratoryResponse = useQuery('getLaboratory', fetchLaboratory, {
        refetchOnWindowFocus: false,
        enabled: false
    });

    useEffect(() => {
        if (account.user?.userType !== STAFF_USER_TYPE) {
            laboratoryResponse.refetch(); // This is be executed when the compoent is mounted
        }
    }, []);

    return (
        <React.Fragment>
            <div className={listClass.join(' ')}>
                {account.user?.userType === STAFF_USER_TYPE ? (
                    <div>
                        <div className="h-list-header">
                            <h4>Prescriptions en attente</h4>
                            <div className="input-group">
                                <FormControl
                                    type="text"
                                    id="search-friends"
                                    placeholder="Rechercher . . ."
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <OverlayTrigger placement="left" overlay={<Tooltip>{`Rafraîchir la liste`}</Tooltip>}>
                                    <Button
                                        className="btn-icon primary"
                                        variant={'outline-default'}
                                        onClick={() => setSearch(search + ' ')}
                                    >
                                        <i className="feather icon-refresh-cw" />
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <div className="h-list-body">
                            <Link to="#" className="h-close-text" onClick={closed}>
                                <i className="feather icon-chevrons-right" />
                            </Link>
                            <div className="main-friend-cont scroll-div">
                                <div className="main-friend-list" style={{ height: 'calc(100vh - 85px)' }}>
                                    <PerfectScrollbar>
                                        <PendingPrescriptions listOpen={listOpen} search={search} />
                                    </PerfectScrollbar>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="h-list-header">Contactez-nous!</div>
                        <div className="p-3 m-t-50 text-center">
                            <h4>La fonctionnalité du Chat sera bientôt disponible pour vous permettre d'échanger facilement avec nous.</h4>
                        </div>
                        <div className="p-3 text-center">
                            <h4>Pour l'instant, contactez-nous directement pour tout besoin.</h4>
                        </div>

                        <div className="p-3 m-t-20 text-center">
                            <h4>
                                <b>{laboratoryResponse.data?.laboratory?.laboratoryName}</b>
                            </h4>
                            <h5>
                                Tél.: <b>{numberWithSpaces(laboratoryResponse.data?.laboratory?.contact)}</b>
                            </h5>
                            <h5>
                                Adresse: <b>{laboratoryResponse.data?.laboratory?.address}</b>
                            </h5>
                        </div>
                        <Link to="#" className="h-close-text" onClick={closed}>
                            <i className="feather icon-chevrons-right" />
                        </Link>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default ChatList;
