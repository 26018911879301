import { capitalize } from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/fr';

moment.locale('fr');
const doualaTimezone = 'Africa/Douala';

export const monthDayYear = (timestamp, timezone) =>
    !timezone ? moment(timestamp).format('Do MMMM YYYY') : moment(timestamp).tz(timezone).format('Do MMMM YYYY');

export const monthDayYearAtTime = (timestamp, timezone) =>
    !doualaTimezone ? moment(timestamp).format('LLLL') : moment(timestamp).tz(doualaTimezone).format('LLLL');
// .format("LLLL");

export const monthDayYearAtTimeStringDate = (timestamp, timezone = doualaTimezone) =>
    !timezone ? moment(timestamp).format('DD-MM-YYYY HH:mm') : moment(timestamp).tz(doualaTimezone).format('DD-MM-YYYY HH:mm');

export const dateAndTime = (timestamp, timezone = doualaTimezone) =>
    !timezone
        ? // ? moment(timestamp).format('Do MMMM YYYY [à] h:mm ')
          moment(timestamp).format('LLL ')
        : moment(timestamp).tz(doualaTimezone).format('LLL ');
// .format('Do MMMM YYYY [à] h:mm ');

export const timeago = (timestamp, timezone) => (!timezone ? moment(timestamp).fromNow() : moment(timestamp).tz(timezone).fromNow());

export const add = (timestamp, amount, range, timezone) =>
    !timezone ? moment(timestamp).add(amount, range).format() : moment(timestamp).tz(timezone).add(amount, range).format();

export const year = (timestamp, timezone) => (!timezone ? moment(timestamp).format('YYYY') : moment(timestamp).tz(timezone).format('YYYY'));

export const iso = (timestamp, timezone) => (!timezone ? moment(timestamp).format() : moment(timestamp).tz(timezone).format());

export const getAge = (timestamp) => {
    let a = moment(Date.now());
    let b = moment(timestamp);

    const years = a.diff(b, 'year');
    b.add(years, 'years');

    const months = a.diff(b, 'months');
    b.add(months, 'months');

    const days = a.diff(b, 'days');

    return (
        (years === 0 ? '' : years + (years === 1 ? ' an ' : ' ans ')) +
        (months === 0 ? '' : months + ' mois ') +
        (days === 0 ? '' : days + (days === 1 ? ' jour' : ' jours'))
    );
};

export const getAgeInYears = (timestamp) => {
    let a = moment(Date.now());
    let b = moment(timestamp);

    return a.diff(b, 'year');
};

export const getAgeInMonths = (timestamp) => {
    let a = moment(Date.now());
    let b = moment(timestamp);

    return a.diff(b, 'month');
};

export const getAgeYearsOnly = (timestamp) => {
    let a = moment(Date.now());
    let b = moment(timestamp);

    const years = a.diff(b, 'year');
    b.add(years, 'years');

    return years === 0 ? '' : years + (years === 1 ? ' an ' : ' ans ');
};

/**
 * @param {*} number
 * @returns an object made of two arrays, an array with the last provided number of days (dates) and another array with the last provided number of days names ()
 * Both in the reversed order
 * [latest, ..., most recent]
 */
export const getLastNumberOfDays = (number) => {
    const finalObject = {
        lastDays: [],
        lastDaysBeginings: [],
        lastDaysEnds: [],
        lastDaysNames: []
    };
    let daysDates = [],
        daysBeginings = [],
        daysEnds = [],
        daysNames = [];
    for (let i = 0; i < number; i++) {
        let d = new Date();
        d.setDate(d.getDate() - i);

        // The actual date is somethng like 2022-01-21T18:57:06.409Z so we substring the first ten caracters to have only the date
        // which is 2022-01-21
        daysDates.push(d.toISOString().substring(0, 10));

        // We also once add the begining and ends of each days so that we have something like:
        // begining: 2022-01-21T00:00Z and end 2022-01-21T23:59Z
        daysBeginings.push(d.toISOString().substring(0, 10) + 'T00:00Z');
        daysEnds.push(d.toISOString().substring(0, 10) + 'T23:59Z');
        daysNames.push(capitalize(moment(d).format(number < 8 ? 'ddd' : 'Do')));
    }
    finalObject.lastDays = daysDates.reverse();
    finalObject.lastDaysBeginings = daysBeginings.reverse();
    finalObject.lastDaysEnds = daysEnds.reverse();
    finalObject.lastDaysNames = daysNames.reverse();

    return finalObject;
};

/**
 * Return a date in the format  01-03-2022 that can be used as default value for type date inputs
 * @param {*} isoDate is an iso Date string
 * @returns
 */
export const getDateString = (isoDate) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
        dt = '0' + dt;
    }
    if (month < 10) {
        month = '0' + month;
    }

    return dt + '-' + month + '-' + year;
};

export const millisecondsToDuration = (duration) => {
    const portions = [];

    const msInDay = 1000 * 60 * 60 * 24;
    const days = Math.trunc(duration / msInDay);
    if (days > 0) {
        portions.push(days + 'J');
        duration = duration - days * msInDay;
    }

    const msInHour = 1000 * 60 * 60;
    const hours = Math.trunc(duration / msInHour);
    if (hours > 0) {
        portions.push(hours + 'h');
        duration = duration - hours * msInHour;
    }

    const msInMinute = 1000 * 60;
    const minutes = Math.trunc(duration / msInMinute);
    if (minutes > 0) {
        portions.push(minutes + 'm');
        duration = duration - minutes * msInMinute;
    }

    const seconds = Math.trunc(duration / 1000);
    if (seconds > 0) {
        portions.push(seconds + 's');
    }

    return portions.join(' ');
};
