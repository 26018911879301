import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LOGOUT } from '../../store/actions';
import ResetPasswordModal from '../ResetPassword/ResetPassworModal';
import { containsElements } from '../../utils/otherUtilities';
import { throttle } from 'lodash';

const AuthorizedGuard = ({ children, authorizedRoles }) => {
    const account = useSelector((state) => state.account);
    const { isLoggedIn } = account;
    const dispatcher = useDispatch();

    // if the token as expired, we logout the user
    // if (account.token?.expiresDate && account.token?.expiresDate < Date.now()) {
    //     dispatcher({
    //         type: LOGOUT,
    //         payload: {
    //             isLoggedIn: false,
    //             user: null,
    //             token: {
    //                 value: '',
    //                 expiresDate: ''
    //             },
    //         }
    //     });
    // }

    useEffect(() => {
        const handleUserActivity = throttle(() => {
            let lastActivity = parseInt(localStorage.getItem("lastActivity"));

            if (isLoggedIn) {
                // Logs the user out after 60 * 60 * 1 * 1000 millis(1h) of inaction
                if (lastActivity && lastActivity + 60 * 60 * 1 * 1000 < Date.now()) {
                    dispatcher({
                        type: LOGOUT,
                        payload: {
                            isLoggedIn: false,
                            user: null,
                            token: {
                                value: '',
                                expiresDate: ''
                            },
                        }
                    });
                } else {
                    localStorage.setItem("lastActivity", Date.now());
                }

            }

        }, 1000); //Call the function once per second thanks to throttle

        // Add event listeners for user activity (e.g., mouse movement, keyboard input)
        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);

        return () => {
            // Clean up event listeners
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
        };
    }, [dispatcher]);


    if (!isLoggedIn) {
        return <Redirect to="/auth/signin" />;
    }

    //Display passowrd reset on first user login
    if (account?.user?.firstLogin) {
        return <ResetPasswordModal
            showModal={true}
            user={account.user}
        />
    }

    if (!containsElements(account?.user?.roles, authorizedRoles)) {
        return <Redirect to="/dashboard" />;
    }

    return children;
};

export default AuthorizedGuard;
