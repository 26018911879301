// action - state management
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT, SET_SAMPLES_DATA, UPDATE_SESSION_TIMEOUT } from './actions';

export const initialState = {
    token: {
        value: '',
        expiresDate: ''
    },
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    samplesData: null,
};

//-----------------------|| ACCOUNT REDUCER ||-----------------------//

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNT_INITIALIZE: {
            const { isLoggedIn, user, token } = action.payload;
            return {
                ...state,
                isLoggedIn,
                isInitialized: true,
                token,
                user
            };
        }
        case LOGIN: {
            const { user } = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                user
            };
        }
        case LOGOUT: {
            localStorage.removeItem("lastActivity");
            return {
                ...state,
                isLoggedIn: false,
                token: {
                    value: '',
                    expiresDate: ''
                },
                user: null
            };
        }
        case SET_SAMPLES_DATA: {
            return {
                ...state,
                samplesData: action.payload
            };
        }
        case UPDATE_SESSION_TIMEOUT: {
            const { expiresDate } = action.payload;
            return {
                ...state,
                token: {
                    value: state.token.value,
                    expiresDate: expiresDate
                },
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
